<template>
    <comp-table
        title="运营商管理"
        ref="comp_table"
        edit-key="operatorId"
        :columns="columns"
        :table-api="tableApi"
        :delete-api="v => '/gateway/api/sycompany/pc/operator/deleteById?operatorId=' + v.operatorId"
        :formComponent="formComponent"
        :operate="getTableOperate"
    >
        <template v-slot:search="data">
            <Input v-model="data.search.operatorName" placeholder="运营商名称" />
        </template>

        <CompModal ref="comp_model" :title="'关联供应商'" height="200" @onClose="closeModal">
            <ElemFormItem title="绑定供应商">
                <elem-label
                    title="请选择供应商"
                    idKey="companyId"
                    valueKey="supplierName"
                    :value="bindShopList"
                    @on-change="changeSelect"
                    :operatorId="operatorId"
                    addType="addType"
                    v-if="showModal"
                ></elem-label>
            </ElemFormItem>

            <template #operate>
                <div class="operate-btns">
                    <Button class="operate-btn highlight" type="primary" @click="onSubmit">确定</Button>
                </div>
            </template>
        </CompModal>
    </comp-table>
</template>

<script>
import Route from "../../../communitymobilization/entity/Route"
import CompTable from "../../../jointly/components/comp-table.vue"
import formComponent from "./formother.vue"
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import ElemFormItem from "../../../jointly/components/elem-form-item.vue"
import ElemLabel from "../../components/elem-label.vue"

export default {
    components: { CompTable, CompModal, ElemFormItem, ElemLabel },

    data() {
        return {
            formComponent: formComponent,

            tableApi: "/gateway/api/sycompany/pc/operator/selectOperatorPage?operatorName=" + "",

            columns: [
                {
                    title: "运营商名称",
                    key: "operatorName",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "运营商渠道ID",
                    key: "merchantId",
                    minWidth: 300,
                    align: "center",
                },
                // {
                //     title: "分账账号",
                //     key: "superAdminAccount",
                //     minWidth: 200,
                //     align: "center",
                // },
                {
                    title: "分账占佣金比例%",
                    key: "prorateRatio",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "管理员账号",
                    key: "superAdminAccount",
                    minWidth: 300,
                    align: "center",
                },
                // {
                //     title: "关联供应商数",
                //     key: "address",
                //     minWidth: 300,
                //     align: "center",
                // },
                // {
                //     title: "关联社区合伙人数",
                //     key: "address",
                //     minWidth: 300,
                //     align: "center",
                // },
                {
                    title: "状态",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("p", {}, params.row.operatorStatus == 0 ? "启用" : "禁用")
                    },
                },
            ],

            bindShopList: [],
            operatorId: "",
            selectIdList: [],
            showModal: false,
        }
    },

    methods: {
        closeModal() {
            this.showModal = false
            console.log("触发关闭按钮")
        },
        changeSelect($event) {
            this.selectIdList = $event.value.keys
        },
        onSubmit() {
            if (!this.selectIdList || !this.selectIdList.length) {
                this.$Message.warning({
                    content: "请选择供应商再进行关联",
                    background: true,
                })
                return
            }
            this.$Message.loading({
                content: "正在关联中，请稍等",
                background: true,
            })
            this.$post(
                "/gateway/api/sycompany/pc/operator/saveBindShop",
                {
                    operatorId: this.operatorId,
                    bindIds: this.selectIdList,
                    shopCat: "1",
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                this.$Message.destroy()
                if (res && res.code == 200) {
                    this.showModal = false
                    this.$Message.success({
                        content: "关联成功",
                        background: true,
                    })
                    this.$refs.comp_model.close()
                } else {
                    this.$Message.error({
                        content: "关联失败",
                        background: true,
                    })
                }
            })
        },
        /**
         * 获取表格操作菜单
         */
        getTableOperate(h, params) {
            return [
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: () => {
                                const d = params.row.operatorStatus

                                this.$post("/gateway/api/sycompany/pc/operator/changeStatus", {
                                    operatorId: params.row.operatorId,
                                    status: d == 1 ? 0 : 1,
                                }).then(res => {
                                    if (res.code == 200) {
                                        this.$Message.success((d == 0 ? "禁用" : "启用") + "成功")
                                        params.row.operatorStatus = d == 1 ? 0 : 1
                                    }
                                })
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    params.row.operatorStatus == 0 ? "禁用" : "启用"
                ),
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: async () => {
                                this.$core.openLayerFrame({
                                    type: 2,
                                    title: "供应商",
                                    content: `/page#/terminal_groupcompany?operatorId=${params.row.operatorId}`,
                                    area: ["100%", "100%"],
                                })
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "供应商"
                ),
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: async () => {
                                this.operatorId = params.row.operatorId
                                this.showModal = false
                                this.$nextTick(async () => {
                                    this.$refs.comp_model.display()
                                    this.showModal = true
                                    this.bindShopList = []
                                    await this.selectBindShop(params.row.operatorId)
                                })
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "关联供应商"
                ),
                h(
                    "DropdownItem",
                    {
                        nativeOn: {
                            click: async () => {
                                this.operatorId = params.row.operatorId
                                this.$core.openLayerFrame({
                                    type: 2,
                                    title: "社区合伙人（导购点）管理",
                                    content: `/page#/terminal_groupothertable?operatorId=${params.row.operatorId}`,
                                    area: ["100%", "100%"],
                                })
                            },
                        },
                        style: {
                            textAlign: "center",
                        },
                    },
                    "社区合伙人（导购点）管理"
                ),
            ]
        },

        async selectBindShop(operatorId) {
            await this.$get("/gateway/api/sycompany/pc/operator/selectBindShop", {
                operatorId,
                shopCat: "1",
            }).then(res => {
                this.bindShopList = res.dataList.join(",")
            })
        },
    },
}
</script>
<style lang="less"></style>
